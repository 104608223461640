<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";
import router from "@/router"
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import {formatDatepicker, formatCurrency, formatNumber} from '@/utils/formatter'

export default {
  page: {
    title: "Create Booking Order",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    DatePicker,
    Multiselect,
    Layout,
    PageHeader,
  },
  setup (props) {
    const { $get } = useHttp()
    const LOV = ref({
      ports: [],
      schedules: [],
    })

    const form = ref({
      departuredate: '',
      origin : '',
      destination : ''
    })

    const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
      setDataParams()
    }

    const setDataParams = async () => {
      if(props.origin){
        form.value.departuredate = props.departure_date//new Date(props.departure_date.replace(/(..)-(..)-(....)/, '$3-$2-$1'))
        form.value.origin = LOV.value.ports.filter(data => data["prt_initial"] === props.origin)[0]
        form.value.destination = LOV.value.ports.filter(data => data["prt_initial"] === props.destination)[0]
        getSchedulelist()
      }
    }

    const getSchedulelist = async() =>{  
        let scheduleParams = {
          departuredate: form.value.departuredate,
          origin: form.value.origin.prt_initial,
          destination : form.value.destination.prt_initial
        }
        const {data} = await $get({ url: 'master/schedule_available', params: scheduleParams })
        LOV.value.schedules = data
    }
    
    const searchFlight = () =>{ 
      getSchedulelist()
      router.replace({ name: 'apps-order-searchflightparams', params: {origin : form.value.origin.prt_initial, destination : form.value.destination.prt_initial, departure_date : form.value.departuredate} })
    }

    onMounted(() => {
      getPorts()
    })

    return {
      LOV,
      form,
      getPorts,
      getSchedulelist,
      searchFlight,
      formatCurrency,
      formatNumber,
      formatDatepicker
    }
  },
  props: {
      departure_date: String,
      origin : String, 
      destination : String
  },
  data() {
    return {
      title: "Create Booking Order",
      items: [],
      select_label: "Select"
    };
  },
  computed: {
  },
   methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    },
    disableDate(date){
      const startdate = new Date()
      startdate.setDate(startdate.getDate() - 1);
      return date < startdate;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">        
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Origin</label>
                    <multiselect id="mv_origin" name="mv_origin" :select-label="select_label" :custom-label="customLabel" v-model="form.origin" :options="LOV.ports"></multiselect>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Destination</label>
                  <multiselect id="mv_destination" name="mv_destination" :select-label="select_label" :custom-label="customLabel" v-model="form.destination" :options="LOV.ports"></multiselect>

                </div>
              </div>

              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername">Departure Date</label>
                  <div class="input-group">
                    <date-picker v-model="form.departuredate" format='DD-MM-YYYY' :disabled-date="disableDate" valueType="format" lang="en"></date-picker>
                  </div>
                </div>
              </div>

              <div class="col-xl-3">
                <div class="form-group position-relative">
                  <label for="validationTooltipUsername"> </label>
                  <div class="input-group">
                    <b-button variant="primary" @click="searchFlight">
                      <i class="ri-search-line align-middle"></i>
                      Search Flight
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-for="(schedule, index) in LOV.schedules" :key="index">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-10">
                <div class="row">
                  <div class="col-xl-3 text-center">
                    <h6>{{schedule.vnd_name}}</h6>
                    <div class="row">
                      <div class="col-12">
                        <img :src='schedule.vnd_logo' width="110" />
                      </div>
                    </div>
                    <h5>{{schedule.sch_flight_no}}</h5>
                  </div>
                  <div class="col-xl-2 text-center">
                    {{schedule.sch_departure_time}}<br/>
                    {{schedule.oriprt_locationname}}<br/>
                    {{schedule.oriprt_initial}}
                  </div>
                  <div class="col-xl-3 text-center">
                    <i class="fas fa-plane"></i><br/>
                    {{Math.floor(schedule.sch_duration/60)}}h {{Math.floor(schedule.sch_duration%60)}}m<br/>
                    <div v-if="schedule.sch_transit > 0">{{schedule.sch_transit}} Transit</div>
                    <div v-else>Direct</div>
                  </div>
                  <div class="col-xl-2 text-center">
                    {{schedule.sch_arrival_time}}<br/>
                    {{schedule.desprt_locationname}}<br/>
                    {{schedule.desprt_initial}}
                  </div>
                  <div class="col-xl-2 text-center">
                    Available
                    <h5>{{ formatNumber(schedule.sch_availablecapacity) }}kg</h5>
                  </div>
                </div>
              </div>
              <div class="col-xl-2 text-center">
                <h5>{{ formatCurrency(schedule.sch_price_kg) }}/kg</h5>
                <b-button variant="primary" :to="{ name: 'apps-order-createinput', params: {schedule_id : schedule.sch_id } }">
                  <i class="ri-arrow-right-s-fill align-middle"></i>
                  Choose
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-10">
                For additional requests from available schedules for extra flights or charter flights. <br/>
                Please contact us on WA or click the following link :
              </div>
              <div class="col-lg-2 text-right">
                <a href="https://api.whatsapp.com/send/?phone=6281319230767&text=Hi+Antarport%2C&app_absent=0" >
                  <span>
                    <img src="@/assets/images/whatsapp.png" alt height="50" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>